/* src/components/Navbar.css */

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #f4f4f4;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-logo img {
    width: 100px;
    height: auto;
  }
  
  .navbar-links {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }
  
  .navbar-links ul {
    list-style: none;
    display: flex;
    gap: 1.5rem;
    margin: 0;
  }
  
  .navbar-links a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .navbar-links a:hover {
    color: #00a1e1;
  }
  
  .navbar-social-icons a {
    font-size: 1.5rem;
    color: #333;
    transition: color 0.3s ease;

    margin-left: -2rem;
    margin-right: 4rem;
  }
  
  .navbar-social-icons a:hover {
    color: #00a1e1;
  }
  
  /* Hamburger Menu Styles */
  .navbar-links-responsive {
    display: none;
  }
  
  .navbar-toggle {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: center;
      padding: 1rem;
    }
  
    .navbar-logo img {
      width: 80px;
      margin-bottom: 1rem;
    }
  
    .navbar-links {
      display: none;
    }
  
    .navbar-links-responsive {
      display: flex;
      gap: 1.5rem;
      margin-top: 1rem;
    }
  
    .navbar-toggle {
      display: block;
    }
  
    /* Adjust the spacing for mobile */
    .navbar-links-responsive a {
      margin: 0.5rem 0;
    }
  }
  