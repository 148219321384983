/* src/components/Contact.css */

.contact-container {
    padding: 4rem 2rem;
    background-color: #f4f4f4;
    text-align: center;
  }
  
  .section-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .contact-info {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #666;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-form input,
  .contact-form button {
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .contact-form input {
    width: 100%;
  }
  
  .contact-form button {
    background-color: #00a1e1;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #0076a3;
  }
  