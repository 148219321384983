/* src/components/Description.css */

.description-container {
    background-color: #f4f4f4;
    padding: 4rem 0;
    text-align: center;
  }
  
  .section-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .description-cards {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .description-card {
    flex-basis: calc(50% - 1rem);
    margin: 0.5rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .description-image {
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .description-content {
    padding: 2rem;
    text-align: left;
  }
  
  .description-content p {
    font-size: 1rem;
    color: #666;
  }
  