/* src/components/Carousel.css */

/* ... (previous styles) ... */

.large-title {
    font-size: 3rem;
    font-weight: bold;
    animation: fadeInUp 2s ease, changeColors 10s infinite alternate;
  }
  
  @keyframes changeColors {
    0%, 100% {
      color: #fff;
    }
    20% {
      color: #ffcc00;
    }
    40% {
      color: #ff6633;
    }
    60% {
      color: #00a1e1;
    }
    80% {
      color: #000;
    }
    100% {
      color: #87CEEB; /* Sky-blue color */
    }
  }
  
  .carousel-title {
    position: absolute;
    font-family: 'Monoton', cursive;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }
  
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .carousel {
      padding: 2rem 1rem;
    }
  
    .carousel-img {
      max-width: 100%;
      height: auto;
    }
  
    .large-title {
      font-size: 2rem;
    }
  
    .carousel-title {
      font-size: 2.5rem;
    }
  }
  