/* src/components/Footer.css */

.footer {
    background-color: #333;
    color: #fff;
    padding: 2rem 0;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-section {
    flex: 1;
    padding: 1rem;
  }
  
  .footer-section h4 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 0.5rem;
  }
  
  .footer-section ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-section ul li a:hover {
    color: #00a1e1;
  }
  
  .footer-text {
    margin-top: 1rem;
    font-size: 0.8rem;
  }
  
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section {
      flex: none;
      margin-bottom: 1rem;
    }
  }
  