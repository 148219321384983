/* src/components/Cards.css */

.cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f5f5f5;
}

.cards-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.cards-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Added flex-wrap property */
  width: 100%;
  margin-bottom: 2rem;
}

.card {
  flex-basis: calc(50% - 1rem); /* Added flex-basis property */
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem; /* Added margin-bottom property */
}

.card-content {
  padding: 1rem;
}

.card-image {
  height: 200px;
  background-size: cover;
  background-position: center;
  margin-bottom: 1rem;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.card-description {
  font-size: 1rem;
  color: #666;
}
